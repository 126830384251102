import React, { useContext, useState, useRef } from 'react'
import AllRelatedVideosBySingleCategory from '../components/SuggestedVideos/AllRelatedVideosBySingleCategory/AllRelatedVideosBySingleCategory'
import AnyVideos from '../components/SuggestedVideos/AnyVideos/AnyVideos'
import BlogContext, { BlogData } from '../contexts/blogData/blogData'
import BlogPost from '../components/blogPost/BlogPost'
import { CampingData } from '../contexts/campingData/campingData'
import CategorySelector from '../components/Selectors/CategorySelector/CategorySelector'
import { computeUniqueCategories } from '../helpers/helpers'
import { getFluidImage } from '../helpers/dynamicGatsbyImage'
import { graphql } from 'gatsby'
import { LodgingData } from '../contexts/lodgingData/lodgingData'
import MediaQuery from '../components/sharedComponents/MediaQuery/MediaQuery'
import MobileCategorySelector from '../components/Selectors/MobileCategorySelector/MobileCategorySelector'
import SEO from '../components/seo'
import SideBar from '../components/sideBar/SideBar'
import Title from '../components/sharedComponents/Title/Title'
import TwoColumnLayout from '../components/twoColumnLayout/TwoColumnLayout'
import { VideoData } from '../contexts/videoData/videoData'
import PropTypes from 'prop-types'
import styles from './../page-css/adventures.module.css'
import CategoryLinkSelector from '../components/Selectors/CategoryLinkSelector/CategoryLinkSelector'
import AdSense from 'react-adsense'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const AdventureContent = ({
  allImageData,
  category,
  onSetCategory,
  onButtonClick,
}) => {
  const matchPoints = MediaQuery(queries)
  const posts = category
    ? useContext(BlogContext)
        .filter(post => {
          if (post.categories.find(y => y === category)) {
            return post
          }
        })
        .map((post, index) => {
          const fluidImg = getFluidImage(post, allImageData.allFile.nodes)
          return (
            <BlogPost
              hero={fluidImg}
              key={index}
              post={post}
              currentCategory={category}
            />
          )
        })
    : useContext(BlogContext).map((post, index) => {
        const fluidImg = getFluidImage(post, allImageData.allFile.nodes)
        return (
          <BlogPost
            hero={fluidImg}
            key={index}
            post={post}
            currentCategory={category}
          />
        )
      })
  const uniqueCategories = computeUniqueCategories(useContext(BlogContext))
  const title = category ? `${category} Adventures` : 'All Adventures'

  return (
    <div>
      {matchPoints && matchPoints.mobile ? (
        <MobileCategorySelector
          category={category}
          onSetCategory={category => onSetCategory(category)}
          onButtonClick={() => onButtonClick()}
          uniqueCategories={uniqueCategories}
        />
      ) : null}
      {matchPoints && matchPoints.desktop ? <Title title={title} /> : null}

      {posts.length ? (
        posts
      ) : (
        <div className={styles.noMatch}>
          Sorry, there are no posts matching the category {`'${category}'`}
        </div>
      )}
    </div>
  )
}

const Adventures = ({ data, location }) => {
  const matchPoints = MediaQuery(queries)
  const myRef = useRef(null)
  const [category, setCategory] = useState(
    location.state ? location.state.category : null
  )
  const onSetCategory = category => {
    setCategory(category)
  }

  const onButtonClick = () => {
    myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  return (
    <div ref={myRef}>
      <SEO
        title="All Adventures"
        description="View all of the adventures we have undertaken on the Cactus Atlas"
        url="https://cactusatlas.com/adventures/"
        type="article"
      />
      <BlogData>
        <CampingData>
          <LodgingData>
            <VideoData>
              <TwoColumnLayout>
                <AdventureContent
                  allImageData={data}
                  category={category ? category : null}
                  onSetCategory={category => onSetCategory(category)}
                  onButtonClick={() => onButtonClick()}
                />
                <SideBar>
                  {category ? (
                    <AllRelatedVideosBySingleCategory category={category} />
                  ) : (
                    <AnyVideos />
                  )}
                  <CategorySelector
                    pageType="adventure"
                    currentCategory={category}
                    onSetCategory={category => onSetCategory(category)}
                    onButtonClick={() => onButtonClick()}
                  />
                  <CategoryLinkSelector />
                  {matchPoints && matchPoints.desktop ? (
                    <AdSense.Google
                      client="ca-pub-1156710604979342"
                      slot="7244598808"
                      style={{
                        display: 'inline-block',
                        width: '300px',
                        height: '250px',
                        position: 'sticky',
                        top: '184px',
                      }}
                    />
                  ) : null}
                </SideBar>
              </TwoColumnLayout>
            </VideoData>
          </LodgingData>
        </CampingData>
      </BlogData>
    </div>
  )
}

export const query = graphql`
  query {
    allFile(filter: { extension: { ne: "md" } }) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
        extension
      }
    }
  }
`

Adventures.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
}

AdventureContent.propTypes = {
  category: PropTypes.string,
  allImageData: PropTypes.object,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
}

export default Adventures
